import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import ComponentStyles from '../brand-styles/templates/product-detail.styles';
import { breakpoints, colors } from '../style-utilities/variables';
import { colorConflict } from '../utilities/colorUtils';
import ReactHtmlParser from 'react-html-parser';
// import components
import ProductDetailHero from '../components/ProductDetail/ProductDetailHero';
import PdpSection from '../components/ProductDetail/PdpSection';
import FloatingProduct from '../components/ProductDetail/FloatingProduct';
import FloatingSubNav from '../components/ProductDetail/FloatingSubNav';
import { element } from 'prop-types';
import { Helmet } from 'react-helmet';
import RelatedRecipes from '../components/RelatedRecipes';
import NutritionPD from '../components/ProductDetail/NutritionPD';
import CookingInstructions from '../components/ProductDetail/CookingInstructions';
import Tips from '../components/ProductDetail/Tips';
import background from '../images/product-detail-bg.png';
import NutritionBG from '../images/craft-bg.png';
import ShareWidget from '../components/ShareWidget';
import RelatedProducts from '../components/ProductDetail/RelatedProducts';
import placeHolderImage from '../images/placeholder.png';

import FavoritesService from '../services/favorites.service';
import UserService from '../services/user.service';
import ReviewsService from '../services/reviews.service';

import ReviewsRating from '../components/Reviews/ReviewRating';
import SEO from '../components/SEO';

const StyledProductDetail = styled.div`
  .shareWidget-bar {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    background: rgba(87, 65, 20, 0.5);
  }
  .page-container {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 8%;
    @media (max-width: ${breakpoints.mobileMax}) {
      padding: 8% 4%;
    }
  }
  .mobile-container {
    #productDetailHeroId {
      text-align: center;
      font-size: 30px;
    }
  }
  #tips {
    background: #c20f2f;
    color: white;
    .content.content-section {
      .img-container div {
        button.wtb {
          background: ${colors.white};
          color: ${colors.primary};
          svg path {
            fill: ${colors.primary};
          }
        }
      }
    }
  }
  #recipes {
    border-bottom: 1px solid lightgray;
  }
  #recipes .content,
  #products .content {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  #products .img-container,
  #recipes .img-container {
    display: none;
  }
  #nutrition {
    #nutritionPDheader {
      color: #fff;
      font-weight: 500;
      font-size: 30px;
      margin-bottom: 18px;
      font-family: Poppins;
      @media (min-width: ${breakpoints.generalDesktop}) {
        font-size: 36px;
      }
    }
  }
  #cooking {
    #cookingInstructionsHeader {
      color: ${colors.primary};
      font-size: 30px;
      margin-bottom: 18px;
      font-family: Poppins;
      @media (min-width: ${breakpoints.generalDesktop}) {
        font-size: 36px;
      }
    }
  }

  #relatedRecipesHeadline {
    color: ${colors.primary};
    font-size: 30px;
    margin-bottom: 18px;
    font-family: Poppins;
    @media (min-width: ${breakpoints.generalDesktop}) {
      font-size: 36px;
    }
  }
  #relatedProductsHeadline {
    color: ${colors.primary};
    font-size: 30px;
    margin-bottom: 18px;
    font-family: Poppins;
    @media (min-width: ${breakpoints.generalDesktop}) {
      font-size: 36px;
    }
  }
  .ps-widget {
    display: none !important;
  }
  h1,
  h3 {
    text-align: center;
  }

  @media (min-width: ${breakpoints.tablet}) {
    .page-container {
      padding-left: 45px;
      padding-right: 45px;
    }
    h1,
    h3 {
      text-align: left;
    }
    #recipes h1,
    #products h1 {
      text-align: center;
    }
  }
  @media (min-width: 900px) {
    .copy-left {
      text-align: left;
    }
  }
  @media (min-width: ${breakpoints.desktop}) {
    .page-container {
      padding-left: 125px;
      padding-right: 125px;
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

class ProductDetail extends Component {
  state = {
    invertCtaBg: false,
    ctaBaseBg: '#3c6bac',
    ctaInvertedBg: '#000000',
    showFloatingProduct: false,
    dietaryArray: [],
    allergenArray: [],
    relatedRecipeArray: [],
    similarProductArray: [],
    cookingImage: '',
    tabPicked: 0
  };
  /**
   * DYNAMIC BASE URL FOR FETCHING
   */
  BASE_URL = `${process.env.WP_BASE_URI}/${
    process.env.ACTIVE_BRAND
  }/wp-json/wp/v2/`;

  psKey = process.env.PS_KEY;

  // All the properties from the current Product being queried from CMS
  data = this.props.data.wordpressWpProducts;
  hideTip = this.data.acf.hide_tips;
  allergens = this.data.attribute_3;
  dietaryConsiderations = this.data.attribute_4;
  productSizes = this.data.acf.product_sizes;
  nutritionLabel = this.data.acf.product_nutrition_label;
  productIngredients = this.data.acf.product_ingredients;
  servingsTable = this.data.acf.stovetop_summary;
  cookingTimeTable = this.data.acf.cooking_times_table;
  stoveInstruction = this.data.acf.cooking_instructions_stovetop;
  microwaveInstruction = this.data.acf.instructions_microwave;
  //Tips section
  tips = {
    title: this.data.acf.title,
    media: this.data.acf.media
      ? this.data.acf.media.source_url
      : placeHolderImage,
    caption: this.data.acf.caption,
    heading: this.data.acf.heading,
    content: this.data.acf.content
  };
  // relatedRecipes = this.data.acf.related_recipes;
  /**
   * RELATED PRODUCTS CMS
   */
  relatedProductsHeadline = this.props.data.wordpressPage.acf
    .related_product_headline;
  relatedProductsSubHeadline = this.props.data.wordpressPage.acf
    .related_product_sub_headline;
  similarProducts = this.data.acf.similar_products;
  /**
   * RELATED RECIPES HEADLINE AND SUB HEADLINE
   */
  relatedRecipesHeadline = this.props.data.wordpressPage.acf
    .related_recipe_headline;
  relatedRecipesSubHeadline = this.props.data.wordpressPage.acf
    .related_recipe_sub_headline;
  // relatedProductThumbnail = this.data.acf.related_thumbnail.source_url;
  allRecipes = this.props.data.allWordpressWpRecipes.edges;
  //Related Recipes: Recipes that use the current product - pulling from related product field in Recipes in CMS
  relatedRecipes = this.allRecipes.filter(item => {
    if (item.node.acf.related_product !== null) {
      return item.node.acf.related_product.some(
        el => el.wordpress_id === this.data.wordpress_id
      );
    }
  });
  //All Main Ingredients with slug and id
  allMainIngredients = this.props.data.allWordpressWpMainIngredient.edges;
  // Dummies data
  dummiesRelatedRecipes = [
    {
      post_title: 'Bruschetta-Style Chicken Pasta',
      post_name: 'bruschetta-style-chicken-pasta'
    },
    {
      post_title: 'Creamy Fettuccine with Bacon and Leeks',
      post_name: 'creamy-fettuccine-with-bacon-and-leeks'
    },
    {
      post_title: 'Easy Chicken with Basil Cream Sauce',
      post_name: 'easy-chicken-with-basil-cream-sauce'
    },
    {
      post_title: 'Grilled Chicken Pesto Pasta',
      post_name: 'grilled-chicken-pesto-pasta'
    }
  ];

  dummiesSimilarProducts = [
    {
      post_title: 'Large Shells',
      post_name: 'large-shells'
    },
    {
      post_title: 'Medium Egg Noodles',
      post_name: 'medium-egg-noodles'
    },
    {
      post_title: 'Penne Rigate',
      post_name: 'penne-rigate'
    },
    {
      post_title: 'Rainbow Twirls',
      post_name: 'rainbow-twirls'
    }
  ];
  async componentWillUnmount() {
    document
      .querySelectorAll('div.ps-lightbox')
      .forEach(e => e.parentNode.removeChild(e));
    document
      .querySelectorAll('div.ps-lightbox-background')
      .forEach(e => e.parentNode.removeChild(e));
  }
  async componentDidMount() {
    const dietaryList = this.dietaryConsiderations.join(',');
    const allergenList = this.allergens.join(',');
    // let relatedRecipeList;
    // let relatedRecipeList =
    //   this.relatedRecipes !== null
    //     ? this.relatedRecipes.map(item => item.node.slug).join(',')
    //     : this.dummiesRelatedRecipes.map(item => item.post_name).join(',');
    let similarProductList =
      this.similarProducts !== null
        ? this.similarProducts.map(item => item.post_name).join(',')
        : this.dummiesSimilarProducts.map(item => item.post_name).join(',');

    const urls = [
      `${this.BASE_URL}attribute_4?include=${dietaryList}`,
      `${this.BASE_URL}attribute_3?include=${allergenList}`,
      // `${this.BASE_URL}recipes?slug=${relatedRecipeList}`,
      `${this.BASE_URL}products?slug=${similarProductList}`
    ];
    await Promise.all(urls.map(url => fetch(url))).then(responses => {
      Promise.all(
        responses.map(response => {
          return response.json();
        })
      )
        .then(results => {
          const diets = results[0].map(diet => diet.name);
          if (typeof results[1].map === 'function') {
            const allergens = results[1].map(item => item.name);
            this.setState({
              allergenArray: allergens
            });
          }
          // const relatedRecipes = results[2];
          const similarProducts = results[2];

          this.setState({
            dietaryArray: diets,
            // relatedRecipeArray: relatedRecipes,
            similarProductArray: similarProducts
          });
        })
        .catch(err => console.log(err));
    });
  }
  tabSelect = tabIndex => {
    this.setState({ tabPicked: tabIndex });
  };

  render() {
    let siteName;
    if (this.props.siteTitle) {
      siteName = this.props.siteTitle;
    }
    let sections = [
      {
        id: 'nutrition',
        type: 'partialWidth',
        sectionImage:
          this.props.data.wordpressWpProducts.acf
            .product_nutrition_section_image !== null
            ? this.props.data.wordpressWpProducts.acf
                .product_nutrition_section_image.source_url
            : this.props.data.wordpressWpProducts.acf.product_image !== null
              ? this.props.data.wordpressWpProducts.acf.product_image.source_url
              : placeHolderImage,
        title: 'Nutrition',
        content: (
          <NutritionPD
            dietaryConsiderations={this.state.dietaryArray}
            productSizes={this.productSizes}
            allergens={this.state.allergenArray}
            nutritionLabel={this.nutritionLabel}
            productIngredients={this.productIngredients}
          />
        )
      },
      {
        id: 'cooking',
        type: 'partialWidth',
        sectionImage:
          this.props.data.wordpressWpProducts.acf.stovetop_section_image !==
          null
            ? this.props.data.wordpressWpProducts.acf.stovetop_section_image
                .source_url
            : this.props.data.wordpressWpProducts.acf.product_image !== null
              ? this.props.data.wordpressWpProducts.acf.product_image.source_url
              : placeHolderImage,
        title: 'Cooking Instructions',
        content: (
          <CookingInstructions
            tabSelect={this.tabSelect}
            stoveInstruction={this.stoveInstruction}
            microwaveInstruction={this.microwaveInstruction}
            servingsTable={this.servingsTable}
            cookingTimeTable={this.cookingTimeTable}
          />
        )
      },
      ...(this.hideTip || this.hideTip === null
        ? []
        : [
            {
              id: 'tips',
              type: 'partialWidth',
              sectionImage:
                this.props.data.wordpressWpProducts.acf.tips_section_image !==
                null
                  ? this.props.data.wordpressWpProducts.acf.tips_section_image.source_url
                  : this.props.data.wordpressWpProducts.acf.product_image !== null
                  ? this.props.data.wordpressWpProducts.acf.product_image.source_url
                  : placeHolderImage,
              title: 'Tips',
              content:
                this.data.acf.title !== '' ? <Tips tips={this.tips} /> : ''
            }
          ]),

      {
        id: 'recipes',
        title: 'Related Recipes',
        type: 'fullWidth',
        content:
          this.relatedRecipes.length > 0 ? (
            <RelatedRecipes
              relatedRecipes={this.relatedRecipes}
              allMainIngredients={this.allMainIngredients}
              relatedRecipesHeadline={ReactHtmlParser(
                this.relatedRecipesHeadline
              )}
              relatedRecipesSubHeadline={ReactHtmlParser(
                this.relatedRecipesSubHeadline
              )}
            />
          ) : (
            ''
          )
      },
      {
        id: 'products',
        title: 'Related Products',
        type: 'fullWidth',
        content:
          this.state.similarProductArray.length > 0 ? (
            <RelatedProducts
              relatedProducts={this.state.similarProductArray}
              relatedProductsHeadline={ReactHtmlParser(
                this.relatedProductsHeadline
              )}
              relatedProductsSubHeadline={ReactHtmlParser(
                this.relatedProductsSubHeadline
              )}
            />
          ) : (
            ''
          )
      }
    ];
    const {
      facebook_url,
      instagram_url,
      youtube_url,
      twitter_url,
      pinterest_url
    } = this.props.data.wordpressWpOptionsPage.acf;

    //configure SEO info for header
    const backupContent = this.props.data.wordpressWpProducts.content.replace(
      /<\/?[^>]+(>|$)/g,
      ''
    );
    const seoInfo = {
      psKey: true,
      title: this.props.data.wordpressWpProducts.yoast_head_json.title,
      ogtitle: this.props.data.wordpressWpProducts.title,
      description:
        this.props.data.wordpressWpProducts.yoast_head_json.description !==
        ''
          ? this.props.data.wordpressWpProducts.yoast_head_json.description
          : backupContent,
      url: this.props.location.href,
      type: 'website',
      twitter: twitter_url,
      image:
        this.props.data.wordpressWpProducts.acf.product_image !== null
          ? this.props.data.wordpressWpProducts.acf.product_image.source_url
          : placeHolderImage
    };

    return (
      <StyledProductDetail>
        <SEO seoInfo={seoInfo} />
        <div
          className="ps-widget"
          ref={this.psWid}
          ps-sku={this.data.acf.product_sku}
        />
        <ProductDetailHero
          content={this.props.data.wordpressWpProducts.content}
          title={ReactHtmlParser(this.props.data.wordpressWpProducts.title)}
          productImage={
            this.props.data.wordpressWpProducts.acf.product_image === null
              ? placeHolderImage
              : this.props.data.wordpressWpProducts.acf.product_image.source_url
          }
          backgroundImage={
            this.props.data.wordpressWpProducts.acf.product_image_hero === null
              ? placeHolderImage
              : this.props.data.wordpressWpProducts.acf.product_image_hero
                  .source_url
          }
          headerId="productDetailHeroId"
          productSku={this.data.acf.product_sku}
        />

        <div className="widget-section">
          <div className="share-widget-container">
            <ShareWidget
              fbMessengerPageName={ReactHtmlParser(
                this.props.data.wordpressWpProducts.title
              )}
              pinterestTitle={ReactHtmlParser(
                this.props.data.wordpressWpProducts.title
              )}
              hideAPinButton={false}
              pinterest_image_url={
                this.props.data.wordpressWpProducts.acf.product_image === null
                  ? placeHolderImage
                  : this.props.data.wordpressWpProducts.acf.product_image
                      .source_url
              }
              className="share-widget"
            />
          </div>
          <div
            className="ps-widget"
            ref={this.psWid}
            ps-sku={this.props.data.wordpressWpProducts.acf.product_sku}
          />
        </div>
        <FloatingSubNav
          navItems={sections
            // .filter(el => el.id !== 'tips')
            .map(section => section)}
          navIds={sections
            // .filter(el => el.id !== 'tips')
            .map(section => section.id)}
          ctaBgShouldInvert={elementBg => {
            this.setState({
              invertCtaBg: colorConflict(elementBg, this.state.ctaBaseBg)
            });
          }}
          handleFloatingProduct={element => {
            // when the active element changes - show the product if it's one of these sections
            this.setState({
              showFloatingProduct: [
                'nutrition',
                'cooking',
                'recipes',
                'products'
              ].includes(element)
            });
          }}
        />
        <div className="product-info">
          {sections
            // .filter(el => el.id !== 'tips')
            .map(({ type, sectionImage, content, id }) => {
              if (content !== '') {
                return (
                  <PdpSection
                    siteTitle={siteName}
                    key={id}
                    type={type}
                    style={sectionImage}
                    sectionId={id}
                    component={content}
                    productSku={this.data.acf.product_sku}
                  />
                );
              } else {
                return '';
              }
            })}
        </div>
      </StyledProductDetail>
    );
  }
}

export default ProductDetail;

export const query = graphql`
  query($slug: String!) {
    wordpressWpProducts(slug: { eq: $slug }) {
      wordpress_id
      title
      content
      attribute_3
      attribute_4
      yoast_head_json {
        title
        description
      }
      acf {
        hide_tips
        product_sizes
        product_serving_size
        product_cooking_time
        short_description
        product_nutrition_label {
          source_url
        }
        product_ingredients
        stovetop_summary
        cooking_times_table
        cooking_instructions_stovetop {
          stovetop_cooking_step
        }
        instructions_microwave {
          microwave_step
        }
        product_image {
          source_url
        }
        product_image_hero {
          source_url
        }
        related_thumbnail {
          source_url
        }
        # related_recipes {
        #   post_name
        #   post_title
        # }
        similar_products {
          post_title
          post_name
        }
        product_nutrition_section_image {
          source_url
        }
        stovetop_section_image {
          source_url
        }
        microwave_section_image {
          source_url
        }
        tips_section_image {
          source_url
        }
        title
        caption
        heading
        content
        product_sku
      }
    }
    wordpressWpOptionsPage(slug: { eq: "site-options" }) {
      acf {
        facebook_url
        instagram_url
        youtube_url
        twitter_url
        pinterest_url
      }
    }
    wordpressPage(slug: { eq: "product-detail" }) {
      yoast_head_json {
        title
        description
      }
      acf {
        related_product_headline
        related_product_sub_headline
        related_recipe_headline
        related_recipe_sub_headline
      }
    }
    allWordpressWpRecipes {
      edges {
        node {
          slug
          title
          main_ingredient
          acf {
            related_product {
              wordpress_id
              post_name
            }
            thumbnail {
              source_url
            }
            total_time
          }
        }
      }
    }
    allWordpressWpMainIngredient {
      edges {
        node {
          wordpress_id
          slug
        }
      }
    }
  }
`;
