import { css } from 'styled-components';
import {
  breakpoints,
  colors,
  fonts,
  rosebud
} from '../../../style-utilities/variables';
import rose from '../../../images/rose_75x73.png';

export default css`
  .related-products {
    .product-card {
      margin: 0 2em;
    }
  }

  .small-img {
    background-image: url(${rose});
    height: ${rosebud.height};
    width: ${rosebud.width};
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
  }
  p {
    margin-top: 0;
    margin-bottom: 30px;
  }
  button {
    margin-top: 30px;
  }

  p.product-text {
    font-family: ${fonts.secondary};
    span {
      color: ${colors.bodyText};
    }
  }

  p.related-prod-link {
    font-size: 24px;
    color: ${colors.primary};
    h3 {
      color: ${colors.primary};
    }
  }
`;
