import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/ProductDetail/RelatedProducts.styles';
import Swiper from 'react-id-swiper/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import ReactHtmlParser from 'react-html-parser';

import { breakpoints } from '../../style-utilities/variables';
import RegularButton from '../RegularButton';
import NeonArrowButton from '../../../src_no-yolks/components/NeonArrowsButton';

import placeHolderImage from '../../images/placeholder.png';
import { colors } from '../../style-utilities/variables';

const StyledProduct = styled(Link)`
  background: transparent;
  min-height: 350px;
  margin-bottom: 0;
  width: 250px;
  margin: auto;
  text-align: center;
  cursor: pointer;

  h1 {
    color: ${colors.primary};
  }
  p {
    margin-top: 0;
  }

  .product-text {
    width: 250px;
    min-height: 100px;
    margin: auto;
    a {
      text-decoration: none;
      font-weight: bold;
      font-size: 16px;
      padding: 12px 0 6px 0;
      display: block;
      color: ${colors.primary};
    }
    p {
      padding: 0;
    }
  }

  &:hover {
    bottom: 10px;
    position: relative;
    transition: bottom 2s ease-out;
  }
  img {
    height: auto;
    width: 250px;
    margin-top: 20px;
  }
`;

const Div = styled.div`
  text-align: center;
  margin-top: 20px;
  .svg-inline--fa.fa-w-8 {
    width: 0.7em;
  }
  .next-button svg,
  .prev-button svg {
    color: #333333;
  }
  p {
    color: #333333;
  }
  .product-text {
    font-size: 16px;
  }
  .next-button,
  .prev-button {
    outline: none;
  }
  .related-products {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .product-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      margin-right: 15px;
      text-align: center;
      width: 200px;
      text-decoration: none;
      @media (min-width: 990px) {
        margin-right: 20px;
      }
    }
    .product-img {
      display: flex;
      align-items: center;
      width: 200px;
      img {
        height: 200px;
        width: 200px;
      }
    }
    .title-text {
      width: 200px;
      height: 200px;
    }
  }
  .related-prod-link {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    padding: 12px 0 6px 0;
    display: block;
    margin: 5px auto 8px;
  }
  .product-short-desc {
    font-size: 13px;
  }
  .Mobile {
    display: block;
    a.swiper-slide {
      text-decoration: none;
      max-width: 793px !important;
      @media (min-width: 900px) {
        display: none;
      }
    }
    @media (min-width: 900px) {
      display: none;
    }
  }
  .Desktop {
    display: none;
    @media (min-width: 900px) {
      display: block;
    }
  }
  .swiper-slide {
    margin-bottom: 12px;
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const RelatedProducts = ({
  relatedProducts,
  relatedProductsHeadline,
  relatedProductsSubHeadline,
  ctaText,
  siteTitle
}) => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    type: 'bullets',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
      dynamicBullets: true
    },
    responsive: [
      { breakpoint: 480, settings: { slidesToShow: 1 } },
      { breakpoint: 769, settings: { slidesToShow: 2 } }
      // { breakpoint: 968, settings: { slidesToShow: 3 } }
      // { breakpoint: 1340, settings: { slidesToShow: 4 } }
    ]
  };

  return (
    <Div>
      {siteTitle === 'mahatma' ? (
        <div className="chalk-heading">
          <h2>{ReactHtmlParser(relatedProductsHeadline)}</h2>
          <div className="chalk-underline" />
        </div>
      ) : (
        <h2 className="relatedProductsHeadline" id="relatedProductsHeadline">
          {ReactHtmlParser(relatedProductsHeadline)}
        </h2>
      )}

      <p id="relatedProductsSubHeadline">
        {ReactHtmlParser(relatedProductsSubHeadline)}
      </p>
      <div className="Desktop">
        <div className="related-products">
          {relatedProducts.map(({ slug, id, title, acf }) =>
            acf.product_image !== null ? (
              <Link className="product-card" key={id} to={`/products/${slug}`}>
                <div className="product-img">
                  <img
                    src={
                      acf.related_thumbnail && acf.related_thumbnail
                        ? acf.related_thumbnail.source_url
                        : acf.product_image.source_url
                    }
                    alt=""
                  />
                </div>
                <div className="title-text">
                  <p className="related-prod-link">
                    {ReactHtmlParser(title.rendered)}
                  </p>
                  <p className="product-text">
                    {ReactHtmlParser(acf.short_description)}
                  </p>
                </div>
              </Link>
            ) : (
              <Link className="product-card" key={id} to={`/products/${slug}`}>
                <div className="product-img">
                  <img
                    src={placeHolderImage}
                    alt=""
                    style={{ borderRadius: '50%' }}
                  />
                </div>
                <div className="title-text">
                  <p className="related-prod-link">
                    {ReactHtmlParser(title.rendered)}
                  </p>
                  <p className="product-text">
                    {ReactHtmlParser(acf.short_description)}
                  </p>
                </div>
              </Link>
            )
          )}
        </div>
      </div>
      <div className="Mobile">
        <Swiper {...settings}>
          {relatedProducts.map(({ slug, id, title, acf }) =>
            acf.product_image !== null ? (
              <StyledProduct key={id} to={`/products/${slug}`}>
                <div>
                  {/* <img src={acf.product_image} alt="" /> */}
                  <img src={acf.related_thumbnail.source_url} alt="" />
                </div>

                <div className="product-text">
                  <p className="related-prod-link">
                    {ReactHtmlParser(title.rendered)}
                  </p>
                  <p className="product-short-desc">
                    {ReactHtmlParser(acf.short_description)}
                  </p>
                </div>
              </StyledProduct>
            ) : (
              <StyledProduct key={id}>
                <div>
                  <img
                    src={placeHolderImage}
                    alt=""
                    style={{ borderRadius: '50%' }}
                  />
                </div>
                <div className="product-text">
                  <p className="related-prod-link">
                    {ReactHtmlParser(title.rendered)}
                  </p>
                  <p className="product-short-desc">
                    {ReactHtmlParser(acf.short_description)}
                  </p>
                </div>
              </StyledProduct>
            )
          )}
        </Swiper>
      </div>

      <div id="button">
        <Link to="/products">
          {/* Temp replacing with Minute text until made dynamic
          <RegularButton>View All Products</RegularButton> */}
          {siteTitle !== 'no-yolks' ? (
            <RegularButton>
              {ctaText
                ? ReactHtmlParser(ctaText.replace('®', '<sup>®</sup>'))
                : 'See All Products'}
            </RegularButton>
          ) : (
            <NeonArrowButton id="showMeMoreBtn">
              {ctaText
                ? ReactHtmlParser(ctaText.replace('®', '<sup>®</sup>'))
                : 'See All Noods'}
            </NeonArrowButton>
          )}
        </Link>
      </div>
    </Div>
  );
};

export default RelatedProducts;
