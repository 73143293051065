import { css } from 'styled-components';
import background from '../../images/bg-pd-page.png';
import nutritionBackground from '../../images/craft-bg.png';
import { colors, fonts, breakpoints } from '../../style-utilities/variables.js';

export default css`
  .domol {
    padding: 60px 15px;
  }
  h1 {
    font-weight: 500;
    font-family: ${fonts.primary}, sans-serif;
  }
  #cooking {
    background-image: url(${background});
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 120%;
    @media (min-width: ${breakpoints.tablet}) {
      background-size: cover;
    }
    background-color: none;
    h1 {
      color: ${colors.primary};
    }
  }
  #nutrition {
    background-image: url(${nutritionBackground});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: none;
    a {
      color: white;
    }
  }
  #relatedRecipes {
    h1 {
      color: ${colors.primary};
    }
  }
  #tips {
    box-shadow: inset 0px 0px 200px rgba(0, 0, 0, 0.6);
  }
  .widget-section {
    padding: 20px;
    @media (min-width: 1100px) {
      .share-widget-container ul {
        -webkit-justify-content: center;
        justify-content: center;
      }
    }
  }
  @media (max-width: 1099px) {
    .img-container {
      display: none;
    }
  }
  @supports (-ms-ime-align: auto) {
    margin-top: 60px;
  }
`;
